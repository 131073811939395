import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import ImgCustom from "../../components/imgCustom/imgCustom"
import links from "../../utils/links"
import BlockConseilsList from "../../components/blockConseilsList/blockConseilsList"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import "./conseilDetail.scss"
import config from "../../utils/config"
import tracking from "../../utils/tracking"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"

const conseilDetail = ({ data, pageContext }) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const post = data.nodePostConseils
  const category = pageContext.category
  const baseUrlCms = config.URL_CMS
  const dataFormated = () => {
    let result = []
    if (data) {
      if (data.hasOwnProperty("allNodePostConseils")) {
        const { edges } = data.allNodePostConseils
        if (edges) {
          edges.forEach(element => {
            const { node } = element
            if (node) {
              if (
                node.title !== post.title &&
                node?.relationships.taxonomy_term__post_categorie !== null
              ) {
                const id =
                  node.relationships.taxonomy_term__post_categorie[0].id
                const name_taxo =
                  node.relationships.taxonomy_term__post_categorie[0].name
                const slug_taxo =
                  node.relationships.taxonomy_term__post_categorie[0].field_slug
                    .value
                const description = node.field_description_article_short.value
                const title = node.title
                const src = node.relationships.field_cover_short.localFile
                const alt = node.field_cover_short.alt
                const slug = node.field_slug

                if (id && name_taxo) {
                  const indexByIdTaxo = result.findIndex(el => el.id === id)
                  const createRow = {
                    category: name_taxo,
                    slug_category: slug_taxo,
                    title: title,
                    description: description,
                    src: src,
                    alt: alt,
                    slug: slug,
                  }
                  if (indexByIdTaxo === -1) {
                    result.push({
                      id: id,
                      category: name_taxo,
                      slug_category: slug_taxo,
                      data: [createRow],
                    })
                  }
                  if (indexByIdTaxo !== -1) {
                    result[indexByIdTaxo].data.push(createRow)
                  }
                }
              }
            }
          })
        }
        // if (result.length) {
        //     result.forEach(elm => {
        //         elm.data.sort((a, b) => (a.order > b.order ? 1 : -1))
        //     })
        // }
      }
    }
    return result
  }

  const dataList = dataFormated()

  const seoPage = {
    title: post.field_titre_article_short
      ? post.field_titre_article_short
      : post.title,
    description: post.field_seo_description
      ? post.field_seo_description
      : post.field_description_article_short.value,
  }

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "article")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categories", "telesurveillance")
    //tracking.trackinit()
  }, [])

  const breadcrumb = [
    {
      name: "Accueil",
      url: links.getLink("home") + campagne,
    },
    {
      name: "Conseils",
      url: links.getLink("conseils") + campagne,
    },
    {
      name: pageContext.category,
      url: `/conseils/${post.relationships.taxonomy_term__post_categorie[0].field_slug.value}` + (isTherecampagne ? "/" + campagne : ""),
    },
    {
      name: post.title,
      url: `/conseils/${post.relationships.taxonomy_term__post_categorie[0].field_slug.value}/${pageContext.slug}` + (isTherecampagne ? "/" + campagne : ""),
    },
  ]

  const formatedParagrapheValue = paragraphe => {
    let t = document.createElement("div")
    t.innerHTML = paragraphe
    if (t) {
      const getAllImgs = t.querySelectorAll("img")
      if (getAllImgs.length > 0) {
        ;[...getAllImgs].forEach(el => {
          const url = new URL(el.src)
          if (url) {
            const path = url.pathname

            if (path) {
              el.src = baseUrlCms + path
            }
          }
        })
      }
    }

    return t
  }

    return (
      <Layout isViewPromoOnHeader={false}>
        <Seo
          title={seoPage.title}
          description={seoPage.description}
          linkCanonical={`conseils/${post.relationships.taxonomy_term__post_categorie[0].field_slug.value}/${pageContext.slug}`}
          urlCoverArticle={post.relationships.field_cover_short.localFile.url}
        />
        <Breadcrumb data={breadcrumb} />
        <div className="container-conseil-detail d-f fd-c ai-c jc-c">
          <span className="c-1">{category}</span>
          <h1>{post.title}</h1>
          <div className="cover-img">
            <ImgCustom
              src={post.relationships.field_cover_short.localFile}
              // alt={post.field_cover_short.alt}
              alt={post.title}
            />
          </div>
          <div className="jc-c ai-c">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: post.field_paragraphe.value.replace("src=\"", "src=\"" + baseUrlCms),
              }}
            ></div>
          </div>
        </div>
        {dataList.length && (
          <div className="suggestion-block bcg-1 ta-c ">
            <h2 className="suggestion-block-title">Vous aimerez peut-être :</h2>
            <div className="suggestion jc-c ai-c d-f fd-r f-w ta-l">
              {dataList.map((el, key) => (
                <div className="d-f ai-fs fd-md-c" key={key}>
                  <BlockConseilsList data={el.data} top={false} threeLast={true} />
                </div>
              ))}
            </div>
          </div>
        )}
      </Layout>
    )
  
}

export const query = graphql`
  query ($slug: String, $limit: Int, $category: String) {
    allNodePostConseils(
      limit: $limit
      sort: { fields: changed, order: DESC }
      filter: {
        relationships: {
          taxonomy_term__post_categorie: {
            elemMatch: { name: { eq: $category } }
          }
        }
      }
    ) {
      edges {
        node {
          title
          field_slug
          field_titre_article_short
          relationships {
            taxonomy_term__post_categorie {
              name
              id
              field_slug {
                value
              }
            }
            field_cover_short {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
          }
          field_description_article_short {
            value
          }
          field_cover_short {
            alt
          }
        }
      }
    }
    nodePostConseils(field_slug: { eq: $slug }) {
      title
      field_slug
      field_titre_article_short
      relationships {
        taxonomy_term__post_categorie {
          name
          id
          field_slug {
            value
          }
        }
        field_cover_short {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(quality: 100, formats: NO_CHANGE)
            }
          }
        }
      }
      field_description_article_short {
        value
      }
      field_paragraphe {
        value
      }
      field_cover_short {
        alt
      }
    }
  }
`

export default conseilDetail
